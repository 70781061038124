.cameraAvatar {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	width: 150px; /* Adjust size as needed */
	height: 150px; /* Adjust size as needed */
	border-radius: 50%;
	border: 2px solid #ddd; /* Optional: Add border */
}

.videoElement {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
