.main {
	background-color: #171D25;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
}

.subtitle {
	color: #FFFFFF;
}

.avatarContainer {
	width: 100%;
	min-height: 85vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.avatar {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200px;
	height: 300px;
	// border: 1px solid #000;
}

.header {
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	padding-right: 10px;
	padding-top: 10px;
}

.loginButton {
	display: flex;
}

.loginModal {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400px;
	background-color: #fff;
	border: 2px solid #000;
	box-shadow: 0px 0px 24px 0px;
	padding: 4px;
}

.personalities {
	width: 90%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	.avatar {
		flex-direction: row;
		flex-wrap: wrap;
		margin-left: 40px;
		margin-right: 40px;
		margin-bottom: 50px;

		&:nth-child(-n + 4) {
			padding-left: 10px;
			padding-right: 10px;
		}

		.subtitle {
			text-align: center;
		}
	}

	.avatarImage {
		margin-left: 10px;
	}
}

.filterButtons {
	width: 100%;
	display: flex;
	justify-content: center;
	padding-top: 60px;
	padding-bottom: 60px;

	.filterButtonGroup {
		margin-left: 30px;
		margin-right: 30px;

		button.MuiToggleButtonGroup-firstButton {
			border-top-left-radius: 50px;
			border-bottom-left-radius: 50px;
			border-right: 0px;
			padding-left: 30px;
		}
		button.MuiToggleButtonGroup-lastButton {
			border-top-right-radius: 50px;
			border-bottom-right-radius: 50px;
			padding-right: 20px;
		}
		button {
			border-width: 2px;
			background-color: #ceccca;
			// color: black;
			&:hover {
				text-decoration: underline;
			}

			&.Mui-selected {
				background-color: #fff7f0;
				border-color: white;
				color: black;

				&:hover {
					background-color: #fff7f0;
					border-color: white;
					color: black;
				}
			}
		}
	}
}

.filterPopover {
	margin-top: 20px;

	.popoverContent {
		min-width: 300px;
		min-height: 200px;
		padding-top: 20px;
		padding-bottom: 20px;

		.listTitle {
			padding-left: 15px;
			font-weight: bold;
		}

		.arrowIcon {
			justify-content: right;
		}

		.listFooter {
			display: flex;
			justify-content: right;
			padding-right: 20px;
		}
	}

	.MuiPaper-rounded {
		border-radius: 20px;
		background-color: #fff7f0;
	}
}
