.pitchPage {
	background-color: #171D25;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100vh;
}

.disclaimer {
	display: block;
	color: #d4d2d1;
	text-align: center;
	padding-top: 30px;
	z-index: 10;
}

.pitchPageContent {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: row;
	padding-top: 100px;

	.vsNameContainer {
		.vsNameBadge {
			display: block;
			background-color: #2b201b;
			border-radius: 10px;
			color: #d4d2d1;
			padding: 10px;
			margin-left: 400px;
			z-index: 10;
		}
	}

	.vsAvatar {
		width: 100%;
		margin-left: 40px;
		margin-right: 40px;
		margin-bottom: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: row;
		flex-wrap: wrap;

		.recordingBox {
			position: absolute;
			width: 500px;
			min-height: 150px;
			// background-color: #2b201b;
			background-image: url("../../public/bg-pixel.png");
			background-repeat: repeat;
			padding: 20px;
			border-radius: 30px;
			color: white;
		}
	}

	.cameraAvatar {
		margin-left: -275px;
		margin-top: -200px;
		z-index: 10;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.interactionControlsWrapper {
		width: 100%;
    	display: flex;
    	justify-content: center;
		.interactionControls {
			padding-left: 20px;
			padding-right: 20px;
			border-radius: 50px;

			.endInteractionBtn {
				border-radius: 50px;
				padding: 10px;
			}
		}
	}

	.avatarVideo {
		width: 100%;
	}

}

.interactionControls {
    position: relative; // Ensure the Card has relative positioning
}

.dropdown {
    position: static !important; // Position it absolutely
    bottom: calc(100% + 5px); // Position above the button with a small gap
    left: 0; // Align to the left
    display: flex; // Use flexbox for vertical alignment
    flex-direction: column; // Stack items vertically
    z-index: 1000; // Ensure it appears above other elements
    background-color: white; // Background color for visibility
    border: 1px solid #ccc; // Border for better definition
    width: 100%; // Optional: make it the same width as the button
}

.videopreload {
	display: none;
}

.chatHistory {
	position: absolute;
	right: 0px;
	bottom: 0px;
	height: 100vh;
	max-width: 25%;
	width: 25%;
	// border: 1px solid #000;
	// justify content at the bottom
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	.chatMessage {
		// make the background color ligher for the user
		background-color: #2b201b;
		color: #d4d2d1;
		padding: 10px;
		margin: 10px;
		border-radius: 20px;

		&.user {
			text-align: right;
			border-bottom-right-radius: 0px;
		}
		&.bot {
			text-align: left;
			border-bottom-left-radius: 0px;
		}

		p {
			margin: 0px;
			padding: 0px;
			padding-top: 10px;
		}
	}
}
